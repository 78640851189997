<template>
    <div>
        <div class="wrap">
            <div class="wrpr">
                <div class="title_area">
                    <h3 class="head">TAG</h3>
                    <div class="tag_name" v-if="isShow">{{getTagName.tag_name}}</div>
                </div>
                <BlogList :getData="getData" :ArticleList="ArticleList" :totalPage="totalPage" :total="total"
                          :tagList="tagList" :queryInfo="queryInfo"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBlogListByTag} from '@/api/tag'
    import BlogList from "../../components/BlogList";

    export default {
        name: "Article",
        data() {
            return {
                queryInfo: {
                    pageNum: 1,
                    pageSize: 5,
                    id: 1,
                },
                tagList: [],
                ArticleList: [],
                categoryList: [],
                totalPage: 0,
                total: 0,
                isShow: false
            }
        },
        components: {BlogList},
        computed: {
            getTagName() {
                return this.tagList.filter(item => {
                    return item.id === parseInt(this.$route.params.id)
                })[0]
            }
        },
        created() {
            this.queryInfo.id = this.$route.params.id
            this.getData()
        },
		watch:{
			$route(to, from) {
				//监听路由是否变化
				if (to.params.id !== from.params.id) {
					this.queryInfo.id = to.params.id;
					this.getData();
				}
			},
		},
        methods: {
            getData() {
				getBlogListByTag(this.queryInfo).then(res => {
                    this.totalPage = Math.ceil(res.data.total / 5)
                    this.ArticleList = res.data.list
                    this.categoryList = res.data.categories
                    this.tagList = res.data.tags
                    this.total = res.data.total
                    this.isShow = true
                })
            },
        },
    }
</script>

<style lang="less" scoped>
    .wrap {

        .wrpr {
            position: relative;
            margin-top: 3em;
            width: 100%;

            .title_area {
                position: relative;
                text-align: center;

                .head {
                    font-size: 150px;
                    color: #23aaff;
                    line-height: 1em;
                    font-weight: 100;
                    font-family: "Times New Roman", Times, serif;
                }

                .tag_name {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    color: #fff;
                    background-color: #000;
                    font-size: 23px;
                    line-height: 1.5em;
                    display: inline-block;
                    padding: 5px 10px;
                    margin: 100px 0 0 0;
                    transform: translate(-50%, 0);
                    -webkit-box-reflect: below 1px linear-gradient(transparent, transparent 50%, rgba(0, 0, 0, 0.3));
                }
            }


        }
    }


</style>