import axios from "../plugin/axios";

export function getBlogListByTag(tagInfo) {
    return axios({
        url: 'get/tag',
        method: 'GET',
        params: {
            ...tagInfo
        }
    })
}